html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.ant-picker-range-arrow {
  display: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 0 10px 10px 0 !important;
}
.ant-picker-focused {
  border-color: #ff6633 !important;
}

.ant-picker-cell-in-view.ant-panel.picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.antpicker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after {
  border-color: #ff6633 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #ff6633 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
    .ant-picker-cell-in-range
  ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
    .ant-picker-cell-in-range
  )::after
  .ant-picker-cell-range-hover-edge-start-near-range {
  border-top: 1px dashed #ff6633 !important;
  border-bottom: 1px dashed #ff6633 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  color: #ffffff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 10px 0 0 10px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #ff6633 !important;
  color: #ffffff !important;
}

.ant-picker-cell-range-start-single {
  border-radius: 10px;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 0 !important;
  width: 254px !important;
}

.ant-picker-header {
  padding: 0 !important;
  width: 254px !important;
  justify-content: space-between !important;
}

.ant-picker-dropdown {
  width: 254px;
}

.ant-picker-dropdown-range {
  padding: 0 !important;
  border-radius: 0 10px 10px 0 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #ff6633 !important;
  color: #ffffff !important;
}

.ant-picker-today-btn {
  color: #ff6633 !important;
}

.ant-picker-today-btn:hover {
  color: rgb(166 60 24 / 50%) !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: #ff6633 !important;
  color: #ffffff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before {
  background: #ff6633 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #ff6633 !important;
  border-radius: 10px !important;
  content: "";
}

.ant-picker {
  border-radius: 10px;
  height: 48px;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-panel-container,
.ant-picker-footer,
.ant-picker-panels,
.ant-picker-date-panel {
  width: 254px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-picker-range {
  border-radius: 10px !important;
  height: 48px;
  width: 254px;
}

.ant-picker-active-bar {
  background: #ff6633 !important;
}

.ant-picker-focused {
  box-shadow: 0 0 0 2px rgba(255, 102, 51, 0.2) !important;
  outline: 0;
}

.ant-picker:hover {
  border-color: #ff6633 !important;
}
