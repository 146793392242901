@font-face {
  font-family: "Twemoji";
  src: url("./assets/font/Twemoji/TwemojiCountryFlags.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/font/Inter/Inter-VariableFont_slnt-wght.ttf")
    format("truetype-variations");
  font-weight: 100 900;
  font-variant: normal !important;
  font-variant-numeric: normal !important;
  font-feature-settings: normal !important;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/font/Rubik/Rubik-VariableFont_wght.ttf")
    format("truetype-variations");
  font-weight: 300 900;
  font-variant: normal !important;
  font-variant-numeric: normal !important;
  font-feature-settings: normal !important;
}

:root {
  --color-light-gray: #e5e5e5;
  --color-black: #222;
  --color-gray: #999;
  --color-green: #21c17a;
  --vh100: calc(100vh - calc(100vh - 100%));
  --color-dark-gray: #757575;
  --toastify-color-progress-light: #ff6633 !important;
  --toastify-color-progress-info: #ff6633;
  --toastify-color-progress-success: #ff6633 !important;
  --toastify-color-progress-warning: #ff6633;
  --toastify-color-progress-error: #ff3358;
}

.Toastify__toast {
  border-radius: 10px !important;
}

input:-internal-autofill-selected {
  appearance: unset;
  background-image: none !important;
  background-color: transparent !important;
  color: unset !important;
}

select,
option {
  font-family: "Twemoji Country Flags", "Google Sans", sans-serif;
  font-weight: bold;
}

*,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
  /*line-height: 1;*/
}

body,
html,
#root {
  background-color: #f7f4f1;
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
  font-variant: normal !important;
  font-feature-settings: normal !important;
}

#root {
  height: 100dvh !important;
}

html {
  overflow-x: hidden;
}

.myIconContentBox {
  border: 2px solid red;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  margin: auto;
  transform: translate(-2px, -4px);
  position: relative;
}

.myIconContentBox.active .myIconContentPopover {
  display: block !important;
}

.myIconContentBox.active {
  border-color: black;
}

.myIconContentBox.edit {
  border-color: #56db40;
}

.myIconContentPopover {
  width: 100%;
  max-width: 300px;
  min-width: 150px;
  position: absolute;
  bottom: 23px;
  left: -65px;
  background-color: #182335;
  padding: 5px;
  border-radius: 5px;
  color: white;
  display: none;
  margin: auto;
}

.myIconContentBoxAnimation {
  border-radius: 50%;
  width: 23px;
  height: 23px;
  background-color: #423189;
  transform: translate(-4px, -4px);
  position: relative;
}

.timeLine {
  padding-top: 5px !important;
}

.ant-timeline-item {
  padding-bottom: 8px !important;
}

.ant-timeline-item-last {
  padding-bottom: 0px !important;
}

@keyframes locationAnimation {
  from {
    transform: scale(1);
    opacity: 0.5;
  }
  to {
    transform: scale(7);
    opacity: 0;
  }
}

.myIconContentBoxAnimation .first-circle,
.myIconContentBoxAnimation .second-circle,
.myIconContentBoxAnimation .third-circle,
.myIconContentBoxAnimation .forth-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #423189;
  border-radius: 50%;
  transform: scale(1);
  animation-duration: 4s;
  animation-name: locationAnimation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.myIconContentBoxAnimation .second-circle {
  animation-delay: 1s;
}

.myIconContentBoxAnimation .third-circle {
  animation-delay: 2s;
}

.myIconContentBoxAnimation .forth-circle {
  animation-delay: 3s;
}

.myIconContentBoxAnimation .core-circle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  height: 80%;
  margin: auto;
  background-color: #423189;
  border: 4px solid white;
  border-radius: 50%;
  transform: scale(1);
  outline: 4px solid #423189;
}

.myIconContentBoxAnimation .core-circle.mainColor,
.myIconContentBoxAnimation {
  background-color: #ff6633;
  outline: #ff6633;
}

.order-placemark {
  width: 60px;
  height: 60px;
  display: flex;
  margin-top: -31px;
  margin-left: -19px;
  position: relative;
  animation: placeMarkFadeIn 0.3s linear;
}


.order-placemark img.placemark {
  width: 100%;
  height: 100%;
  margin: auto;
}

.order-placemark img.vehicle {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 15%;
  left: 25%;
  right: 0;
  bottom: 0;
}

@keyframes placeMarkFadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.Toastify__toast--default {
  background-color: #ff6633 !important;
  color: #fdfdfd !important;
  border-radius: 14px !important;
  box-shadow: 0 2px 40px 0 rgba(153, 48, 14, 0.44) !important;
  min-height: 88px;
  height: fit-content;
  width: 392px;
}

.Toastify__toast-container {
  width: max-content !important;
}

.Toastify__progress-bar-theme--light {
  background: unset;
}

.Toastify__progress-bar--default,
.Toastify__progress-bar {
  background-color: #fdfdfd !important;
}

.ant-btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #000;
  height: 40px;
  border-radius: 10px;
  box-shadow: 1px 0 13px 6px rgba(0, 0, 0, 0.63);
  -webkit-box-shadow: 1px 0 13px 6px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 1px 0 13px 6px rgba(0, 0, 0, 0.63);
}

.ant-btn:hover {
  opacity: 0.7;
  background-color: #fff;
  color: #000;
}

.ant-btn span {
  margin-left: 11px;
}

.ant-btn-primary {
  color: #000;
  border: none;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-dropdown.ant-dropdown-placement-bottomRight
  .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  background-color: white;
  color: #000;
}

.ant-dropdown.ant-dropdown-placement-bottomRight
  .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  background-color: #ff6633;
  color: white;
  transition-duration: 0s;
}

.ant-dropdown-menu-item {
  padding: 8px 12px !important;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  border-radius: 12px;
  overflow: hidden;
}

.ymaps-2-1-79-gotoymaps__container,
.ymaps-2-1-79-gototech,
.ymaps-2-1-79-gototaxi,
.ymaps-2-1-79-copyright__link {
  display: none !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container,
  .Toastify__toast-container--top-right {
    width: 312px !important;
    left: auto !important;
    right: 0 !important;
  }
}

@media only screen and (max-width: 330px) {
  .Toastify__toast-container,
  .Toastify__toast-container--top-right {
    width: 100vw !important;
    left: 0 !important;
    right: 0 !important;
  }
}

.polyline-point {
  width: 10px;
  height: 10px;
  background-color: white;
  margin-top: -4.5px;
  margin-left: -4px;
  border-radius: 100%;
  position: relative;
  border: 1px solid black;
}

.polyline-point .time {
  display: none;
  position: absolute;
  bottom: 60%;
  left: 60%;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.polyline-point:hover .time,
.polyline-point .active {
  display: block !important;
}
